import { FC } from 'react';
import { ContentSection, HR } from '~/components';
import { Frontmatter } from '~/config';
import mp4 from '~/video/Looking-Off.mp4';
import webm from '~/video/Looking-Off.webm';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: Content Section',
  order: 1,
  screenShoot: false,
};

const LibraryPage: FC = () => {
  const content = (
    <>
      <div tw="h-16" />
      <h1>Hello H1!</h1>
      <h2>Hello H2!</h2>
      <h3>Hello H3!</h3>
      <p>
        Hello paragraph! Farm-to-table affogato locavore, intelligentsia paleo
        lyft pitchfork fingerstache meh cray echo park put a bird on it truffaut
        art party. XOXO tilde farm-to-table chicharrones aesthetic subway tile
        humblebrag, deep v pinterest narwhal biodiesel bushwick.
      </p>
      <div tw="h-16" />
    </>
  );
  return (
    <>
      <HR cyan />
      <ContentSection>{content}</ContentSection>
      <HR cyan />
      <ContentSection flavor="dark">{content}</ContentSection>
      <HR cyan />
      <ContentSection flavor="riskyGray">{content}</ContentSection>
      <HR cyan />
      <ContentSection flavor="circle">{content}</ContentSection>
      <HR cyan />
      <ContentSection flavor="circle-alt">{content}</ContentSection>
      <HR cyan />
      <ContentSection flavor="dark" mp4Path={mp4} webmPath={webm}>
        {content}
      </ContentSection>
      <HR cyan />
    </>
  );
};

export default LibraryPage;
